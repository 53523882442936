import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  useTheme,
  styled,
  Card,
  Avatar,
  Fade,
  Grow,
  Zoom,
} from '@mui/material';
import {
  Code as CodeIcon,
  Brush as BrushIcon,
  Speed as SpeedIcon,
  Devices as DevicesIcon,
  Lightbulb as LightbulbIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import type { Theme } from '@mui/material';

const AboutContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(10, 4),
  background: theme.palette.gradient.background,
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(6, 2),
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
    width: '150%',
    height: '150%',
    background: `radial-gradient(circle at center, ${theme.palette.secondary.main}15 0%, transparent 70%)`,
    animation: 'rotate 20s linear infinite',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'translate(-50%, -50%) rotate(0deg)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) rotate(360deg)',
    },
  },
}));

const ContentSection = styled(motion.div)(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
  position: 'relative',
  zIndex: 1,
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1),
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 800,
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  background: theme.palette.gradient.primary,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  [theme.breakpoints.down('md')]: {
    fontSize: '2.2rem',
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
    marginBottom: theme.spacing(3),
  },
}));

const FeatureCard = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  transition: 'all 0.3s ease',
  border: `1px solid ${theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(0, 0, 0, 0.1)'}`,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    gap: theme.spacing(1.5),
  },
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: `0 20px 40px ${theme.palette.primary.main}20`,
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: theme.shape.borderRadius * 1.5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.gradient.primary,
  boxShadow: `0 8px 20px ${theme.palette.primary.main}40`,
  '& svg': {
    fontSize: 30,
    color: '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    width: 50,
    height: 50,
    '& svg': {
      fontSize: 24,
    },
  },
}));

const FeatureTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 700,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
}));

const FeatureDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  lineHeight: 1.8,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    lineHeight: 1.6,
  },
}));

const JourneySection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(10),
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.paper,
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2.5,
  border: `1px solid ${theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(0, 0, 0, 0.1)'}`,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(3, 2),
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

const TimelineItem = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(6),
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  '&:last-child': {
    marginBottom: 0,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 20,
    top: 40,
    bottom: -40,
    width: 2,
    background: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      left: 18,
    },
    [theme.breakpoints.down('sm')]: {
      left: 16,
      top: 32,
      bottom: -32,
    },
  },
  '&:last-child::before': {
    display: 'none',
  },
}));

const TimelineDot = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '50%',
  background: theme.palette.gradient.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  boxShadow: `0 4px 20px ${theme.palette.primary.main}40`,
  '& svg': {
    fontSize: 20,
    color: '#fff',
  },
  [theme.breakpoints.down('md')]: {
    width: 36,
    height: 36,
    '& svg': {
      fontSize: 18,
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: 32,
    height: 32,
    '& svg': {
      fontSize: 16,
    },
  },
}));

const TimelineContent = styled('div')(({ theme }) => ({
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    paddingRight: theme.spacing(1),
  }
}));

const TimelineTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 700,
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  lineHeight: 1.3,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.15rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: theme.spacing(0.5),
  }
}));

const TimelineDate = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    fontSize: '0.85rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    marginBottom: theme.spacing(0.5),
  }
}));

const TimelineDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  lineHeight: 1.6,
  [theme.breakpoints.down('md')]: {
    fontSize: '0.95rem',
    lineHeight: 1.5,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.85rem',
    lineHeight: 1.4,
  }
}));

interface FeatureType {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const features: FeatureType[] = [
  {
    title: 'Web Developer',
    description: 'Specializing in building modern, responsive web applications using cutting-edge technologies and best practices.',
    icon: <CodeIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: 'Product Manager',
    description: 'Leading product strategy, roadmap planning, and cross-functional teams to deliver impactful solutions.',
    icon: <SpeedIcon sx={{ fontSize: 40 }} />,
  },
  {
    title: 'Problem Solver',
    description: 'Tackling complex challenges with analytical thinking and innovative solutions.',
    icon: <LightbulbIcon sx={{ fontSize: 40 }} />,
  },
];

interface JourneyItemType {
  title: string;
  date: string;
  description: string;
  icon: React.ReactNode;
}

const journey: JourneyItemType[] = [
  {
    title: 'Started Learning Web Development',
    date: '2018',
    description: 'Started my web development journey with frontend technologies (HTML, CSS, JavaScript) and frameworks (jQuery, Bootstrap). Expanded into backend development with Java and Spring ecosystem (Core, Boot, MVC, Data, Security).',
    icon: <CodeIcon />,
  },
  {
    title: 'Built First Project',
    date: '2019',
    description: 'I built my first project, a simple website using HTML, CSS, and JavaScript.',
    icon: <DevicesIcon />,
  },
  {
    title: 'Started Working as a Freelancer',
    date: '2020',
    description: 'I started working as a freelancer, building websites and web applications for clients.',
    icon: <SpeedIcon />,
  },
];

const About: React.FC = () => {
  const theme = useTheme();

  return (
    <AboutContainer>
      <ContentSection
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <SectionTitle variant="h2">
          About Me
        </SectionTitle>

        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={feature.title}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2, duration: 0.8 }}
              >
                <FeatureCard
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <IconWrapper>
                    {React.cloneElement(feature.icon as React.ReactElement, {
                      sx: { fontSize: { xs: 32, sm: 36, md: 40 } }
                    })}
                  </IconWrapper>
                  <FeatureTitle variant="h3">
                    {feature.title}
                  </FeatureTitle>
                  <FeatureDescription>
                    {feature.description}
                  </FeatureDescription>
                </FeatureCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>

        <JourneySection>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <Typography 
              variant="h3" 
              sx={{ 
                fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
                fontWeight: 700,
                textAlign: 'center',
                mb: { xs: 3, sm: 4, md: 5 },
                color: 'text.primary'
              }}
            >
              My Journey
            </Typography>

            <Box sx={{ px: { xs: 0.5, sm: 1, md: 2 } }}>
              {journey.map((item, index) => (
                <TimelineItem
                  key={item.title}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.6 + index * 0.2, duration: 0.8 }}
                >
                  <TimelineDot>
                    {React.cloneElement(item.icon as React.ReactElement, {
                      sx: { fontSize: { xs: 16, sm: 18, md: 20 } }
                    })}
                  </TimelineDot>
                  <TimelineContent>
                    <TimelineDate>{item.date}</TimelineDate>
                    <TimelineTitle variant="h4">
                      {item.title}
                    </TimelineTitle>
                    <TimelineDescription>
                      {item.description}
                    </TimelineDescription>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Box>
          </motion.div>
        </JourneySection>
      </ContentSection>
    </AboutContainer>
  );
};

export default About;
