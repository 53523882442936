// src/components/Footer.tsx
import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  IconButton,
  useTheme
} from '@mui/material';
import { 
  GitHub, 
  LinkedIn, 
  Twitter, 
  Email 
} from '@mui/icons-material';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();

  return (
    <Box 
      component="footer" 
      sx={{
        background: theme.palette.gradient.background,
        backdropFilter: 'blur(10px)',
        color: 'text.primary',
        py: 6,
        mt: 8,
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '1px',
          background: theme.palette.gradient.primary,
        }
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                background: theme.palette.gradient.primary,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                fontWeight: 'bold'
              }}
            >
              Farrukh Gulomov
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Web Developer | Product Manager | Bank Accountant
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
            <Box>
              <IconButton 
                href="https://github.com/FarrukhGulomov" 
                target="_blank" 
                sx={{
                  color: 'text.primary',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    background: theme.palette.gradient.primary,
                    color: 'white'
                  }
                }}
              >
                <GitHub />
              </IconButton>
              <IconButton 
                href="https://www.linkedin.com/in/farrukh-gulomov-b143281a2/" 
                target="_blank" 
                sx={{
                  color: 'text.primary',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    background: theme.palette.gradient.primary,
                    color: 'white'
                  }
                }}
              >
                <LinkedIn />
              </IconButton>
              <IconButton 
                href="https://x.com/farruhfahr?s=35" 
                target="_blank" 
                sx={{
                  color: 'text.primary',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    background: theme.palette.gradient.primary,
                    color: 'white'
                  }
                }}
              >
                <Twitter />
              </IconButton>
              <IconButton 
                href="mailto:gulomovfarruh@gmail.com" 
                sx={{
                  color: 'text.primary',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    background: theme.palette.gradient.primary,
                    color: 'white'
                  }
                }}
              >
                <Email />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Box 
          sx={{ 
            mt: 4, 
            pt: 3,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: '25%',
              right: '25%',
              height: '1px',
              background: theme.palette.gradient.primary,
              opacity: 0.5
            }
          }}
        >
          <Typography 
            variant="body2" 
            align="center" 
            sx={{ 
              color: 'text.secondary',
              '& span': {
                background: theme.palette.gradient.primary,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
              }
            }}
          >
            {currentYear} All Rights Reserved.
            <br />
            Designed and Developed by Farrukh Gulomov
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;