import { useState, useMemo, useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { getTheme } from './theme/theme';
import About from './components/About';
import Contact from './components/Contact';
import Hero from './components/Hero';
import Skills from './components/Skills';
import Portfolio from './components/Portfolio';
import Blog from './components/Blog';
import Footer from './components/Footer';

function App() {
  const [mode, setMode] = useState<'light' | 'dark'>('dark');
  
  const theme = useMemo(() => getTheme(mode), [mode]);

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };


  // Yo'naltirish uchun useEffect qo'shish
  useEffect(() => {
    if (window.location.hostname === 'farukh.uz') {
      window.location.href = 'https://www.farukh.uz' + window.location.pathname;
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Hero />
        <About />
        <Skills />
        <Portfolio />
        <Blog />
        <Contact />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;