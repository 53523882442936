import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Chip,
  Stack,
  Avatar,
  IconButton,
  Divider,
} from '@mui/material';
import {
  Share as ShareIcon,
  Bookmark as BookmarkIcon,
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarIcon,
} from '@mui/icons-material';

interface BlogPost {
  title: string;
  excerpt: string;
  content: string;
  image: string;
  date: string;
  category: string;
  tags: string[];
  readTime: string;
  link: string;
  author: {
    name: string;
    avatar: string;
  };
}

const blogPosts: BlogPost[] = [
  {
    title: 'My Journey from Developer to Product Manager',
    excerpt: 'Personal insights and lessons learned during my transition from development to product management.',
    content: 'Full content will be stored in a separate markdown file',
    image: '/projects/1111.webp',
    date: 'March 15, 2024',
    category: 'Career Growth',
    tags: ['Product Management', 'Career Development'],
    readTime: '5 min read',
    link: '/blog/journey-dev-to-pm',
    author: {
      name: 'Farrukh Gulomov',
      avatar: '/farruh.jpg',
    },
  },
  {
    title: 'Building Modern Web Applications: My Tech Stack Choice',
    excerpt: 'Why I chose specific technologies for my projects and what I learned along the way.',
    content: 'Full content will be stored in a separate markdown file',
    image: '/projects/2.webp',
    date: 'March 10, 2024',
    category: 'Technology',
    tags: ['Web Development', 'React', 'Spring Boot'],
    readTime: '4 min read',
    link: '/blog/modern-tech-stack',
    author: {
      name: 'Farrukh Gulomov',
      avatar: '/farruh.jpg',
    },
  },
  {
    title: 'Problem-Solving Approach in Software Development',
    excerpt: 'My personal methodology for tackling complex technical challenges.',
    content: 'Full content will be stored in a separate markdown file',
    image: '/projects/3.webp',
    date: 'March 5, 2024',
    category: 'Development',
    tags: ['Problem Solving', 'Best Practices', 'Software Development'],
    readTime: '6 min read',
    link: '/blog/problem-solving-approach',
    author: {
      name: 'Farrukh Gulomov',
      avatar: '/farruh.jpg',
    },
  },
];

const Blog: React.FC = () => {
  return (
    <Box
      id="blog"
      sx={{
        py: 8,
        bgcolor: 'background.default',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h2"
          align="center"
          gutterBottom
          sx={{ mb: 2, fontWeight: 'bold' }}
        >
          My Blog
        </Typography>
        <Typography
          variant="h6"
          component="p"
          align="center"
          color="text.secondary"
          sx={{ mb: 6 }}
        >
          Sharing my thoughts, experiences, and insights about technology and product management
        </Typography>

        <Grid container spacing={4}>
          {blogPosts.map((post, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: (theme) => theme.shadows[8],
                  },
                }}
              >
                <CardActionArea href={post.link}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={post.image}
                    alt={post.title}
                    sx={{ objectFit: 'cover' }}
                  />
                  <CardContent>
                    <Chip
                      label={post.category}
                      size="small"
                      sx={{ mb: 2 }}
                      color="primary"
                    />
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="h3"
                      sx={{ 
                        fontWeight: 'bold',
                        minHeight: 64,
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      {post.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 2,
                        minHeight: 60,
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                      }}
                    >
                      {post.excerpt}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar
                          src={post.author.avatar}
                          alt={post.author.name}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Typography variant="caption" color="text.secondary">
                          {post.author.name}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AccessTimeIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                        <Typography variant="caption" color="text.secondary">
                          {post.readTime}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ mt: 2 }}
                      flexWrap="wrap"
                      gap={1}
                    >
                      {post.tags.map((tag, tagIndex) => (
                        <Chip
                          key={tagIndex}
                          label={tag}
                          size="small"
                          variant="outlined"
                          sx={{ fontSize: '0.75rem' }}
                        />
                      ))}
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Blog;
