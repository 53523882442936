import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  useTheme,
  styled,
  LinearProgress,
  Card,
  Fade,
  Grow,
  Zoom,
  Stack,
} from '@mui/material';
import {
  Code as CodeIcon,
  Storage as StorageIcon,
  Brush as BrushIcon,
  Build as BuildIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import type { Theme } from '@mui/material';

const fadeInUp = {
  initial: { y: 60, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  transition: { duration: 0.6, ease: "easeOut" }
};

const staggerContainer = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const GlassCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(10px)',
  borderRadius: '20px',
  border: `1px solid ${theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(255, 255, 255, 0.5)'}`,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 8px 32px rgba(0, 0, 0, 0.3)'
      : '0 8px 32px rgba(31, 38, 135, 0.15)',
  },
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(0, 0, 0, 0.1)',
  '& .MuiLinearProgress-bar': {
    borderRadius: 4,
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
  },
}));

const CategoryIcon = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  color: theme.palette.primary.contrastText,
  marginBottom: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'rotate(360deg) scale(1.1)',
    boxShadow: `0 0 20px ${theme.palette.primary.main}40`,
  },
}));

const GradientText = styled(Typography)<{ component?: React.ElementType }>(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 800,
  letterSpacing: '-0.02em',
  textShadow: theme.palette.mode === 'dark'
    ? '0 0 30px rgba(255, 255, 255, 0.2)'
    : '0 0 30px rgba(0, 0, 0, 0.1)',
}));

const SkillsContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(10, 4),
  background: theme.palette.gradient.background,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '200%',
    height: '200%',
    background: `radial-gradient(circle at center, ${theme.palette.primary.main}15 0%, transparent 70%)`,
    animation: 'pulse 15s ease-in-out infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(1)',
    },
    '50%': {
      transform: 'translate(-50%, -50%) scale(1.2)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(1)',
    },
  },
}));

const ContentSection = styled(motion.div)(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
  position: 'relative',
  zIndex: 1,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 800,
  marginBottom: theme.spacing(8),
  textAlign: 'center',
  background: theme.palette.gradient.primary,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const SkillCard = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  transition: 'all 0.3s ease',
  border: `1px solid ${theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(0, 0, 0, 0.1)'}`,
  boxShadow: theme.shadows[1],
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: `0 20px 40px ${theme.palette.primary.main}20`,
  },
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& svg': {
    color: theme.palette.primary.main,
  },
}));

const SkillItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const SkillName = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  height: 8,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  position: 'relative',
}));

const ProgressBarFill = styled(motion.div)<{ value: number }>(({ theme, value }) => ({
  height: '100%',
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.gradient.primary,
  width: `${value}%`,
  position: 'absolute',
  top: 0,
  left: 0,
  boxShadow: `0 0 10px ${theme.palette.primary.main}40`,
}));

interface SkillType {
  name: string;
  level: number;
}

interface SkillCategoryType {
  category: string;
  skills: SkillType[];
  icon: React.ReactNode;
}

const skillCategories: SkillCategoryType[] = [
  {
    category: 'Frontend Development',
    skills: [
      { name: 'React/Next.js', level: 80 },
      { name: 'TypeScript', level: 70 },
      { name: 'Material-UI', level: 85 },
      { name: 'CSS/SASS', level: 90 },
    ],
    icon: <CodeIcon sx={{ fontSize: 40 }} />,
  },
  {
    category: 'Backend Development',
    skills: [
      { name: 'Spring Boot', level: 95 },
      { name: 'Python', level: 70 },
      { name: 'PostgreSQL', level: 85 },
      { name: 'RESTful APIs', level: 90 },
    ],
    icon: <StorageIcon sx={{ fontSize: 40 }} />,
  },
  {
    category: 'Tools & Others',
    skills: [
      { name: 'Git/GitHub', level: 90 },
      { name: 'Docker', level: 80 },
      { name: 'AWS', level: 75 },
      { name: 'CI/CD', level: 85 },
    ],
    icon: <BuildIcon sx={{ fontSize: 40 }} />,
  },
];

const Skills: React.FC = () => {
  const theme = useTheme();

  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const progressVariants = {
    hidden: { width: 0 },
    visible: (value: number) => ({
      width: `${value}%`,
      transition: {
        duration: 1,
        ease: "easeOut",
        delay: 0.2
      }
    })
  };

  return (
    <SkillsContainer>
      <ContentSection
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <SectionTitle variant="h2">
          My Skills
        </SectionTitle>

        <Grid container spacing={4}>
          {skillCategories.map((category, categoryIndex) => (
            <Grid item xs={12} md={4} key={category.category}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: categoryIndex * 0.2, duration: 0.8 }}
              >
                <SkillCard
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <CategoryTitle>
                    {category.icon}
                    {category.category}
                  </CategoryTitle>

                  {category.skills.map((skill, skillIndex) => (
                    <SkillItem key={skill.name}>
                      <SkillName>
                        {skill.name}
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'primary.main',
                            fontWeight: 600,
                          }}
                        >
                          {skill.level}%
                        </Typography>
                      </SkillName>
                      <ProgressBarContainer>
                        <ProgressBarFill
                          value={skill.level}
                          initial={{ width: 0 }}
                          animate={{ width: `${skill.level}%` }}
                          transition={{
                            delay: (categoryIndex * category.skills.length + skillIndex) * 0.1,
                            duration: 1,
                            ease: "easeOut"
                          }}
                        />
                      </ProgressBarContainer>
                    </SkillItem>
                  ))}
                </SkillCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </ContentSection>
    </SkillsContainer>
  );
};

export default Skills;
