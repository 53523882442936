// src/components/Hero.tsx
import React from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  useTheme,
  styled,
  Link,
  TypographyProps,
} from '@mui/material';
import {
  GitHub as GitHubIcon,
  LinkedIn as LinkedInIcon,
  Instagram as InstagramIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';

const HeroContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  position: 'relative',
  overflow: 'hidden',
  background: theme.palette.gradient.background,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '200%',
    height: '200%',
    background: `radial-gradient(circle at center, ${theme.palette.primary.main}15 0%, transparent 70%)`,
    animation: 'pulse 15s ease-in-out infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(1)',
    },
    '50%': {
      transform: 'translate(-50%, -50%) scale(1.2)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(1)',
    },
  },
}));

const ContentWrapper = styled(motion.div)(({ theme }) => ({
  maxWidth: '1200px',
  width: '100%',
  zIndex: 1,
  backdropFilter: 'blur(10px)',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '30px',
  padding: theme.spacing(8),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  border: `1px solid ${theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(255, 255, 255, 0.5)'}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(8),
  margin: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    margin: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    margin: theme.spacing(1),
    gap: theme.spacing(3),
  }
}));

const TextContent = styled('div')(({ theme }) => ({
  flex: 1,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  }
}));

const PhotoContainer = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  width: '300px',
  height: '300px',
  flexShrink: 0,
  [theme.breakpoints.down('md')]: {
    width: '250px',
    height: '250px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '200px',
    height: '200px',
  }
}));

const ProfileImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '20px',
});

const SocialIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  }
}));

const ScrollDownButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
  zIndex: 2,
  animation: 'bounce 2s infinite',
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateX(-50%) translateY(0)',
    },
    '40%': {
      transform: 'translateX(-50%) translateY(-10px)',
    },
    '60%': {
      transform: 'translateX(-50%) translateY(-5px)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '3.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  }
}));

const SubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
  }
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.95rem',
  }
}));

const PhotoFrame = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: '20px',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(45deg, transparent 40%, rgba(255, 255, 255, 0.2) 50%, transparent 60%)',
    filter: 'brightness(1.5)',
    animation: 'shine 3s infinite',
  },
  '@keyframes shine': {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '100%': {
      transform: 'translateX(100%)',
    },
  },
}));

const Photo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '20px',
});

const FloatingShape = styled('div')<{ index: number }>(({ theme, index }) => ({
  position: 'absolute',
  width: '40px',
  height: '40px',
  borderRadius: '12px',
  background: theme.palette.gradient.secondary,
  boxShadow: `0 4px 12px ${theme.palette.secondary.main}40`,
  animation: `float${index} 4s ease-in-out infinite`,
  '@keyframes float1': {
    '0%, 100%': {
      transform: 'translate(0, 0) rotate(0deg)',
    },
    '50%': {
      transform: 'translate(-20px, -20px) rotate(45deg)',
    },
  },
  '@keyframes float2': {
    '0%, 100%': {
      transform: 'translate(0, 0) rotate(0deg)',
    },
    '50%': {
      transform: 'translate(20px, -20px) rotate(-45deg)',
    },
  },
  '@keyframes float3': {
    '0%, 100%': {
      transform: 'translate(0, 0) rotate(0deg)',
    },
    '50%': {
      transform: 'translate(-20px, 20px) rotate(-45deg)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '30px',
    height: '30px',
  }
}));

const GradientText = styled(Typography)<{ component?: React.ElementType }>(({ theme }) => ({
  background: theme.palette.gradient.primary,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 800,
  letterSpacing: '-0.02em',
  textShadow: `0 0 30px ${theme.palette.primary.main}20`,
  fontSize: '3.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
    textAlign: 'center',
  }
}));

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  lineHeight: 1.8,
  fontSize: '1.1rem',
  maxWidth: '800px',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    marginBottom: theme.spacing(4),
    lineHeight: 1.6,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.95rem',
    marginBottom: theme.spacing(3),
    lineHeight: 1.5,
    textAlign: 'center',
  }
}));

const ScrollIndicator = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 2,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  }
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
  width: 50,
  height: 50,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  background: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(0, 0, 0, 0.05)',
  '&:hover': {
    transform: 'translateY(-5px) rotate(8deg)',
    background: theme.palette.gradient.primary,
    boxShadow: `0 8px 20px ${theme.palette.primary.main}40`,
    '& svg': {
      color: '#fff',
      transform: 'scale(1.2)',
    }
  },
  '& svg': {
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    fontSize: 24,
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.down('sm')]: {
    width: 40,
    height: 40,
    margin: theme.spacing(0.5),
    '& svg': {
      fontSize: 20,
    }
  }
}));

const SocialIconsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  }
}));

const Hero: React.FC = () => {
  const theme = useTheme();
  const name = 'Farrukh Gulomov';
  const title = 'Web Developer | Product Manager | Bank Accountant';
  const description = 'I combine my expertise in web development, product management, and banking to create innovative digital solutions. With a unique blend of technical and financial knowledge, I deliver high-quality web applications while ensuring robust financial management.';
  
  const socialLinks = [
    {
      name: 'GitHub',
      url: 'https://github.com/farrukhgulomov',
      icon: <GitHubIcon />,
    },
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/farrukh-gulomov-b143281a2?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
      icon: <LinkedInIcon />,
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/farruh_gulomov?igsh=eDNxNHp1b2Zwbm51&utm_source=ig_contact_invite',
      icon: <InstagramIcon />,
    },
  ];

  return (
    <HeroContainer>
      <Container maxWidth="lg" sx={{ position: 'relative' }}>
        <ContentWrapper
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <TextContent>
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.8 }}
            >
              <GradientText variant="h1" component="h1">
                Hi, I'm {name}
              </GradientText>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.8 }}
            >
              <SubTitle variant="h2" component="h2">
                {title}
              </SubTitle>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7, duration: 0.8 }}
            >
              <DescriptionText>
                {description}
              </DescriptionText>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.9, duration: 0.8 }}
            >
              <SocialIconsContainer>
                {socialLinks.map((link, index) => (
                  <motion.div
                    key={link.name}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={link.name}
                    >
                      <SocialIconButton>
                        {link.icon}
                      </SocialIconButton>
                    </a>
                  </motion.div>
                ))}
              </SocialIconsContainer>
            </motion.div>
          </TextContent>

          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.5, duration: 0.8, ease: "easeOut" }}
          >
            <PhotoContainer>
              <FloatingShape 
                index={1} 
                style={{ 
                  top: '-20px', 
                  right: '-20px', 
                  transform: 'rotate(45deg)',
                }} 
              />
              <FloatingShape 
                index={2} 
                style={{ 
                  bottom: '-20px', 
                  left: '-20px', 
                  transform: 'rotate(-45deg)',
                }} 
              />
              <FloatingShape 
                index={3} 
                style={{ 
                  bottom: '-10px', 
                  right: '40px', 
                  width: '30px', 
                  height: '30px',
                  transform: 'rotate(30deg)',
                }} 
              />
              <PhotoFrame>
                <Photo 
                  src="/farruh.jpg" 
                  alt={name} 
                />
              </PhotoFrame>
            </PhotoContainer>
          </motion.div>
        </ContentWrapper>

        <ScrollIndicator
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            y: [0, 10, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: "reverse",
          }}
          onClick={() => {
            window.scrollTo({
              top: window.innerHeight,
              behavior: 'smooth'
            });
          }}
        >
          <Typography 
            variant="button" 
            sx={{ 
              fontSize: '1rem', 
              mb: 1,
              textTransform: 'none',
              fontWeight: 500,
              letterSpacing: '0.5px',
              textShadow: theme.palette.mode === 'dark' 
                ? '0 2px 4px rgba(0,0,0,0.5)' 
                : '0 2px 4px rgba(0,0,0,0.2)',
              color: theme.palette.mode === 'dark' 
                ? theme.palette.primary.light
                : theme.palette.primary.main,
            }}
          >
            Scroll Down
          </Typography>
          <KeyboardArrowDownIcon sx={{ fontSize: '2rem' }} />
        </ScrollIndicator>
      </Container>
    </HeroContainer>
  );
};

export default Hero;