import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
  Chip,
  Fade,
  Grow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Project {
  id: number;
  title: string;
  description: string;
  image: string;
  technologies: string[];
  githubUrl: string;
  liveUrl: string;
  longDescription: string;
  features: string[];
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-12px)',
    boxShadow: theme.shadows[10],
    '& .MuiCardMedia-root': {
      transform: 'scale(1.05)',
    },
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 240,
  transition: 'transform 0.3s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    height: 200,
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.main + '20',
  color: theme.palette.primary.main,
  fontWeight: 500,
  '&:hover': {
    backgroundColor: theme.palette.primary.main + '30',
  },
}));

const ProjectDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.background.paper,
    backgroundImage: 'none',
  },
}));

const Portfolio: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const projects: Project[] = [
    {
      id: 1,
      title: 'E-commerce Platform',
      description: 'A modern e-commerce platform built with React and Spring Boot.',
      image: '/images/projects/ecommerce.jpg',
      technologies: ['Spring Boot', 'PostgreSQL'],
      githubUrl: 'https://github.com/FarrukhGulomov/pcmarket_app/tree/master',
      liveUrl: 'https://pcmarket.uz/',
      longDescription: 'A full-featured e-commerce platform with user authentication, product management, shopping cart, and payment integration.',
      features: [
        'User authentication and authorization',
        'Product catalog with search and filters',
        'Shopping cart and wishlist',
        'Secure payment processing',
        'Order management and tracking',
      ],
    },
    {
      id: 2,
      title: 'Money Transfer API',
      description: 'A secure and efficient API for seamless money transfers.',
      image: '/images/taskmanager.jpg',
      technologies: ['Spring Boot', 'Spring Security', 'PostgreSql'],
      githubUrl: 'https://github.com/FarrukhGulomov/jwttask',
      liveUrl: '',
      longDescription: 'A robust and secure API designed to facilitate money transfers with real-time processing and high scalability. The API ensures secure transactions using modern authentication mechanisms and supports multi-currency transactions. It also provides audit trails and detailed transaction logging for compliance.',
      features: [
        'P2P Operation',
        'Get Balance',
        'Transaction History',
        'Secure and Real-Time Money Transfers',
        'User authentication and authorization',
      ],
    },
    {
      id: 3,
      title: 'AI Image Generator',
      description: 'An AI-powered image generation tool using deep learning models.',
      image: '/images/ai-generator.jpg',
      technologies: ['Python', 'TensorFlow', 'React', 'Flask', 'Docker'],
      githubUrl: 'https://github.com/username/ai-image-generator',
      liveUrl: 'https://ai-image-demo.com',
      longDescription: 'An innovative image generation tool that leverages deep learning models to create unique artwork. Users can input text descriptions or modify existing images to generate new, creative visuals.',
      features: [
        'Deep learning-based image generation',
        'Text-to-image functionality',
        'Image modification and editing',
        'User authentication and authorization',
        'Detailed Transaction Logs and Audit Trails',
      ],
    },
  ];

  const handleOpenDialog = (project: Project) => {
    setSelectedProject(project);
  };

  const handleCloseDialog = () => {
    setSelectedProject(null);
  };

  return (
    <Box
      component="section"
      id="portfolio"
      sx={{
        py: { xs: 8, md: 12 },
        background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
      }}
    >
      <Container maxWidth="lg">
        <Fade in timeout={1000}>
          <Box sx={{ mb: { xs: 6, md: 8 }, textAlign: 'center' }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              My Projects
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{
                maxWidth: '800px',
                mx: 'auto',
                px: { xs: 2, md: 0 },
                fontSize: { xs: '1rem', sm: '1.1rem' },
              }}
            >
              Here are some of my recent projects that showcase my skills and experience
            </Typography>
          </Box>
        </Fade>

        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Grow
                in
                timeout={(index + 1) * 500}
                style={{ transformOrigin: '0 0 0' }}
              >
                <StyledCard>
                  <StyledCardMedia
                    image={project.image}
                    title={project.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      {project.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      paragraph
                      sx={{ mb: 2 }}
                    >
                      {project.description}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {project.technologies.map((tech) => (
                        <StyledChip key={tech} label={tech} size="small" />
                      ))}
                    </Box>
                  </CardContent>
                  <CardActions sx={{ p: 2, pt: 0 }}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => handleOpenDialog(project)}
                      endIcon={<OpenInNewIcon />}
                    >
                      Learn More
                    </Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                      href={project.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                      sx={{ color: 'text.secondary' }}
                    >
                      <GitHubIcon />
                    </IconButton>
                    {project.liveUrl && (
                      <IconButton
                        href={project.liveUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="small"
                        sx={{ color: 'text.secondary' }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    )}
                  </CardActions>
                </StyledCard>
              </Grow>
            </Grid>
          ))}
        </Grid>

        <ProjectDialog
          open={!!selectedProject}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
          TransitionComponent={Grow}
          TransitionProps={{ timeout: 500 }}
        >
          {selectedProject && (
            <>
              <DialogTitle
                sx={{
                  pb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h4" component="div" sx={{ fontWeight: 600 }}>
                  {selectedProject.title}
                </Typography>
                <IconButton
                  onClick={handleCloseDialog}
                  sx={{
                    color: 'text.secondary',
                    transition: 'all 0.2s',
                    '&:hover': {
                      transform: 'rotate(90deg)',
                      color: 'text.primary',
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Box sx={{ mb: 3 }}>
                  <img
                    src={selectedProject.image}
                    alt={selectedProject.title}
                    style={{
                      width: '100%',
                      borderRadius: theme.shape.borderRadius,
                      marginBottom: theme.spacing(3),
                    }}
                  />
                  <Typography variant="body1" paragraph>
                    {selectedProject.longDescription}
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                    Key Features:
                  </Typography>
                  <ul style={{ paddingLeft: theme.spacing(2) }}>
                    {selectedProject.features.map((feature, index) => (
                      <li key={index}>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          paragraph={index !== selectedProject.features.length - 1}
                        >
                          {feature}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {selectedProject.technologies.map((tech) => (
                    <StyledChip key={tech} label={tech} />
                  ))}
                </Box>
              </DialogContent>
              <DialogActions sx={{ p: 2.5 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  href={selectedProject.githubUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<GitHubIcon />}
                >
                  View Source
                </Button>
                {selectedProject.liveUrl && (
                  <Button
                    variant="contained"
                    color="primary"
                    href={selectedProject.liveUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    endIcon={<OpenInNewIcon />}
                  >
                    Live Demo
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </ProjectDialog>
      </Container>
    </Box>
  );
};

export default Portfolio;
